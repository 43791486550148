import { MenuEntry } from '@pancakeswap-libs/uikit'
import { getFesBNBStakingAddress, getFesBNBAddress } from 'utils/addressHelpers'

const config: MenuEntry[] = [
  {
    label: 'Home / Staking',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Contracts / Charts',
    icon: 'InfoIcon',
    items: [
      {
        label: 'XERS Contract',
        href: `https://etherscan.com/address/${getFesBNBAddress()}#code`,
      },
      {
        label: 'XERS Chart',
        href: `https://www.dextools.io/app/en/ether/pair-explorer/0x39d7a3486d13afd8c2b57b89eda3fef627b82000`,
      },
      {
        label: 'XERS Staking Contract',
        href: `https://etherscan.com/address/${getFesBNBStakingAddress()}#code`,
      },
    ],
  },
  {
    label: 'Learn More',
    icon: 'RoadmapIcon',
    items: [
      {
        label: 'Website',
        href: 'https://www.xprojecterc.com/',
      },
    ],
  },
]

export default config
